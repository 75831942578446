/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    bool, func, number, shape, string,
} from 'prop-types';
import { trackEvent } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import ScrollToTopButton from '../../../ScrollToTopButton';
import StyledButton from '../../../../../EnterpriseDesignSystem/Button/StyledButton';
import triggerPopChatEvent from '../../../../../MultiBrandChatController/AigoChatWidgetWrapperV2/popOpenChat';
import { getUserSubmittedProductFilterZipcode } from '../../../../../../../state/ducks/App/App-Selectors';

const useStyles = makeStyles((theme) => ({
    loadMoreBox: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    loadMoreCount: {
        display: 'flex',
        justifyContent: 'center',
        padding: '7px',
    },
    loadMoreBtnContainer: {
        margin: '0 auto 2em',
        '&:has(button>span>div>svg) button': {
            width: '128px !important',
            height: '36px !important',
            '& span': {
                marginTop: '7px',
            },
        },
    },
    loadMoreOutline: {
        minWidth: '145px',
        minHeight: '37px',
        padding: '6px 20px 6px',
        color: theme.palette.cta1,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid',
        borderColor: theme.palette.cta1,
        borderRadius: 0,
        cursor: 'pointer',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '18px',
        transition: 'all .2s',
        marginRight: '15px',
        textAlign: 'center',
    },
    loadMoreBtnMobile: {
        minWidth: '145px',
        padding: '6px 20px 6px',
        color: theme.palette.cta1,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid',
        borderColor: theme.palette.cta1,
        borderRadius: 0,
        cursor: 'pointer',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '18px',
        transition: 'all .2s',
        '&:hover': {
            backgroundColor: theme.palette.paper,
            color: theme.palette.background.cta1,
        },
    },
    loadMoreBtn: {
        padding: '8px 20px 9px',
        color: theme.palette.cta1,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid',
        borderColor: theme.palette.cta1,
        borderRadius: 0,
        cursor: 'pointer',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '18px',
        transition: 'all .2s',
        '&:hover': {
            backgroundColor: theme.palette.cta1,
            color: theme.palette.background.paper,
        },
        marginRight: '15px',
    },
    CircularProgress: {
        textAlign: 'center',
        width: '20px !important', // overwriting the default values
        height: '20px !important', // overwriting the default values
        margin: '0 auto',
    },
}));

const LoadMoreButton = ({
    isLoadMoreAvailable, onLoadMore, productToLoad, tracking, totalProducts, setLoading, loading, ffIsLoadMoreOptimizationEnabled, hasMore, totalProductsToLoad, ffIsLoadMoreCountShown,
}) => {
    const dispatch = useDispatch();
    const userSubmittedZip = useSelector(getUserSubmittedProductFilterZipcode);
    const [buttonLoading, setButtonLoading] = useState(false);
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width: 767px)');
    useEffect(() => {
        if (ffIsLoadMoreOptimizationEnabled && totalProducts) {
            setButtonLoading(false);
        }
    }, [totalProducts]);
    if (!hasMore) {
        return <></>;
    }
    const clickHandler = () => {
        triggerPopChatEvent({ loadMoreButtonClick: true });
        dispatch(trackEvent(tracking));
        if (ffIsLoadMoreOptimizationEnabled) {
            setButtonLoading(true);
        } else {
            setLoading(true);
        }
        /**
        * if flag is enabled the value or arg 2 should be false
        */
        onLoadMore(productToLoad, !(ffIsLoadMoreOptimizationEnabled));
    };

    const remainingProductsToLoad = totalProductsToLoad - totalProducts;
    return (
        <div className={classes.loadMoreBox}>
            {isLoadMoreAvailable && ffIsLoadMoreCountShown ? (
                <>
                    {!userSubmittedZip && <span className={classes.loadMoreCount}>{`Showing 1-${totalProducts} of ${totalProductsToLoad} Items`}</span>}
                    <div className={classes.loadMoreBtnContainer}>
                        {!(loading || buttonLoading) ? (
                            // <button
                            <StyledButton type="button" label={userSubmittedZip ? 'View More' : `View ${remainingProductsToLoad < productToLoad ? remainingProductsToLoad : productToLoad} More`} handleOnClick={clickHandler} variant="secondary" attributes={{ 'data-testid': 'loadMore', 'aria-labelledby': 'loadMore' }} />
                        ) : (
                            <StyledButton type="button" label={<CircularProgress className={classes.CircularProgress} attributes={{ 'aria-labelledby': 'progress' }} />} variant="secondary" />
                        )}
                    </div>
                </>
            )
                : !isMobile && <ScrollToTopButton />}
            {isMobile && <ScrollToTopButton />}
        </div>
    );
};
LoadMoreButton.propTypes = {
    isLoadMoreAvailable: bool.isRequired,
    loading: bool.isRequired,
    setLoading: func.isRequired,
    onLoadMore: func.isRequired,
    productToLoad: number.isRequired,
    ffIsLoadMoreOptimizationEnabled: bool,
    tracking: shape({
        eventCategory: string,
        eventAction: string,
        eventLabel: string,
    }),
    totalProducts: number,
    hasMore: bool,
    totalProductsToLoad: number,
    ffIsLoadMoreCountShown: bool,
};

LoadMoreButton.defaultProps = {
    tracking: {},
    ffIsLoadMoreOptimizationEnabled: false,
    totalProducts: 0,
    hasMore: false,
    totalProductsToLoad: 0,
    ffIsLoadMoreCountShown: false,
};

export default LoadMoreButton;
