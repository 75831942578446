/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useMemo } from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import {
    object, string, arrayOf, shape, bool, number, array, func, oneOfType,
} from 'prop-types';

// import { actions as tagManagerActions } from '../../../../../../../../state/ducks/TagManager';
import ConnectedDesktopSimpleProduct from './DesktopSimpleProduct/DesktopSimpleProduct';
import noop from '../../../../../../../helpers/noop';

// import DesktopMobile from '../../../../../../../helpers/DesktopMobile/DesktopMobile'; TODO: remove if test works

export const GraphqlSimpleProduct = ({
    subcollectionV2Style,
    quickview,
    categoryData,
    productSkus,
    filterOptions,
    path,
    breadCrumbArray,
    skuPriceRange,
    availability,
    image,
    name,
    partNumber,
    fromCategoryPage,
    productReviews,
    prices,
    url,
    productNotAvaliable,

    // Category
    categoryId,
    categoryName,
    categoryLegacyId,
    recommendedForYou,
    recommendedTitle,

    skipAddons,
    showMovieUpsell,
    // tracking
    track,
    trackData,
    trackEvent,
    isPassportEligible,
    presentation_family,
    absoluteImagePath,

    urlPromoPackage,
    shouldRequestEarliestDeliveryDate,

    productBrand,
    positionInCategory,
    isSubcollectionBanner,
    nextEarliestDeliveryDateLoad,
    isCategoryPage,
    // abtest remove if no longer needed
    showSimpleProductRedesignAbTest = true,
    productType,
    experiments,
    productClickCallback,
    collectionPageHierarchyEnabled,
    isDeliveryTypeEnabled,
    PLARecommendations,
}) => {
    const ProductItem = useMemo(() => (
        <ConnectedDesktopSimpleProduct
            quickview={quickview}
            name={name}
            partNumber={partNumber}
            url={url}
            prices={prices}
            image={image}
            path={path}
            isSubcollectionBanner={isSubcollectionBanner}
            filterOptions={filterOptions}
            categoryData={categoryData}
            fromCategoryPage={fromCategoryPage}
            productReviews={productReviews}
            breadCrumbArray={breadCrumbArray}
            skuPriceRange={skuPriceRange}
            availability={availability}
            categoryId={categoryId}
            categoryName={categoryName}
            categoryLegacyId={categoryLegacyId}
            skipAddons={skipAddons}
            showMovieUpsell={showMovieUpsell}
            recommendedForYou={recommendedForYou}
            recommendedTitle={recommendedTitle}
            trackEvent={trackEvent}
            track={track}
            trackData={trackData}
            isPassportEligible={isPassportEligible}
            presentationFamily={presentation_family}
            absoluteImagePath={absoluteImagePath}
            urlPromoPackage={urlPromoPackage}
            shouldRequestEarliestDeliveryDate={shouldRequestEarliestDeliveryDate}
            productSkus={productSkus}
            productNotAvaliable={productNotAvaliable}
            productBrand={productBrand}
            subcollectionV2Style={subcollectionV2Style}
            positionInCategory={positionInCategory}
            showSimpleProductRedesignAbTest={showSimpleProductRedesignAbTest}
            nextEarliestDeliveryDateLoad={nextEarliestDeliveryDateLoad}
            isCategoryPage={isCategoryPage}
            productType={productType}
            experiments={experiments}
            productClickCallback={productClickCallback}
            collectionPageHierarchyEnabled={collectionPageHierarchyEnabled}
            isDeliveryTypeEnabled={isDeliveryTypeEnabled}
            PLARecommendations={PLARecommendations}
        />
    ), []);
    return ProductItem;
};

GraphqlSimpleProduct.propTypes = {
    subcollectionV2Style: bool,
    quickview: bool,
    categoryData: object,
    filterOptions: object,
    prices: array,
    path: string,
    name: string.isRequired,
    productReviews: object.isRequired,
    partNumber: string,
    url: string.isRequired,
    categoryId: string,
    categoryName: string,
    categoryLegacyId: string,
    breadCrumbArray: arrayOf(
        shape({
            title: string.isRequired,
            href: string.isRequired,
        }),
    ),
    skuPriceRange: shape({
        sale: arrayOf(
            shape({
                value: number.isRequired,
            }),
        ),
        retail: arrayOf(
            shape({
                value: number.isRequired,
            }),
        ),
    }).isRequired,
    availability: shape({
        deliveryMessage: string.isRequired,
    }).isRequired,
    fromCategoryPage: bool,
    image: shape({
        name: string.isRequired,
    }).isRequired,
    skipAddons: bool,
    showMovieUpsell: oneOfType([
        bool,
        shape({
            icon: shape({
                content_type: string,
                file_size: string,
                filename: string,
                is_dir: bool,
                title: string.isRequired,
                uid: string,
                url: string.isRequired,
            }).isRequired,
            products: arrayOf(
                shape({
                    sku: string.isRequired,
                    sku_description: string.isRequired,
                    sku_price: number.isRequired,
                }).isRequired,
            ).isRequired,
        }),
    ]),
    track: bool,
    trackEvent: func,
    isPassportEligible: bool,
    presentation_family: string,
    trackData: shape({
        tracking_event_action: string,
        tracking_event_category: string,
    }),
    absoluteImagePath: bool,
    urlPromoPackage: shape({
        hasPromo: bool.isRequired,
        code: string.isRequired,
        type: string.isRequired,
        amount: number.isRequired,
    }),
    shouldRequestEarliestDeliveryDate: bool,
    nextEarliestDeliveryDateLoad: bool,
    productSkus: arrayOf(
        shape({
            id: string.isRequired,
            partNumber: string.isRequired,
        }).isRequired,
    ),
    productBrand: string,
    recommendedForYou: bool.isRequired,
    recommendedTitle: string,
    positionInCategory: number,
    productNotAvaliable: bool,
    showSimpleProductRedesignAbTest: bool.isRequired,
    isSubcollectionBanner: bool,
    isCategoryPage: bool,
    productType: string,
    experiments: array,
    productClickCallback: func,
    collectionPageHierarchyEnabled: bool,
    isDeliveryTypeEnabled: bool,
    PLARecommendations: bool,
};

GraphqlSimpleProduct.defaultProps = {
    subcollectionV2Style: false,
    quickview: false,
    categoryData: null,
    filterOptions: {},
    prices: null,
    path: '',
    categoryId: '',
    categoryName: '',
    categoryLegacyId: '',
    isSubcollectionBanner: false,
    isPassportEligible: null,
    presentation_family: '',
    partNumber: '',
    fromCategoryPage: false,
    skipAddons: false,
    showMovieUpsell: false,
    track: false,
    trackEvent: noop,
    breadCrumbArray: [],
    trackData: {},
    absoluteImagePath: false,
    urlPromoPackage: {
        hasPromo: false,
        code: '',
        type: '',
        amount: 0,
    },
    shouldRequestEarliestDeliveryDate: false,
    nextEarliestDeliveryDateLoad: false,
    productSkus: null,
    productBrand: '',
    recommendedTitle: '',
    positionInCategory: 0,
    productNotAvaliable: false,
    isCategoryPage: false,
    productType: '',
    experiments: [],
    productClickCallback: noop,
    collectionPageHierarchyEnabled: false,
    isDeliveryTypeEnabled: false,
    PLARecommendations: false,
};

export default GraphqlSimpleProduct;
